<template>
  <div>
    <section-banner :model="section"></section-banner>
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <v-form @submit.prevent="resetPassword">
                <v-text-field
                  v-model="reset.password"
                  label="Password"
                  name="password"
                  prepend-icon="mdi-lock"
                  :rules="passwordRules"
                  type="password"
                  required
                />
                <v-text-field
                  v-model="reset.confirm"
                  label="Confirm"
                  name="confirm"
                  prepend-icon="mdi-lock"
                  :rules="confirmPasswordRules"
                  type="password"
                  required
                />
                <div class="text-center mt-4">
                  <v-btn class="btn__primary-colour" x-large dark type="submit"
                    >Change Password</v-btn
                  >
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import SectionBanner from "@/components/Frontend/SectionBanner";
export default {
  components: {
    SectionBanner,
  },
  data() {
    return {
      reset: {
        password: "",
        confirm: "",
      },
      passwordRules: [
        (v) => !!v || "Please type password.",
        (v) => (v && v.length >= 6) || "minimum 6 characters",
      ],
      confirmPasswordRules: [
        (v) => !!v || "type confirm password",
        (v) =>
          v === this.reset.password ||
          "The password confirmation does not match.",
      ],
      section: {
        BannerSmall: true,
        Title: "Reset Password",
        Description: "Please reset and confirm password below.",
        img: require("@/assets/img/background.jpg"),
      },
    };
  },
  methods: {
    async resetPassword() {
      const self = this;
      try {
        await this.$apollo
          .mutate({
            mutation: gql`
              mutation resetPassword($resetToken: String!, $password: String!) {
                resetPassword(resetToken: $resetToken, password: $password) {
                  id
                  updated
                }
              }
            `,
            variables: {
              resetToken: self.$route.params.id,
              password: self.reset.password,
            },
          })
          .then((response) => {
            self.$swal({
              title: "Success",
              text:
                "Password was successfully reset, please <a href='/login'>login</a>",
              icon: "success",
              confirmButtonColor: '#c79477',
            });
          });
      } catch (e) {
        console.log(`Reset Password Failed: ${JSON.stringify(e)}`);
        self.errorMsg = `${e.message}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
